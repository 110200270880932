import React from "react";
import { withUnpublishedPreview } from 'gatsby-source-prismic'

import SEO from "./../components/Global/SEO";
import RouteTemplate from './../templates/route';

const Page404 = ({data}) => {
  return (
    <>
      <SEO title="Pagina niet gevonden" description="Pagina niet gevonden" />
      <div className="container">Pagina niet gevonden</div>
    </>
  );
}

export default withUnpublishedPreview(Page404, {
  templateMap: {
    route: RouteTemplate,
  },
})